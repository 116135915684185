<template>
  <div v-loading="loading" class="content">
    <!-- 上边 -->
    <el-row style="margin-bottom:10px">
      <el-col :span="12" style="display: flex;">
        <search @success="init()">
          <el-form>
            <el-form-item label="库存金额为0 ：" :label-width="formLabelWidth">          
              <el-radio-group v-model="listQuery.hideEndAmount0">
                <el-radio :label="true">隐藏</el-radio>
                <el-radio :label="false">显示</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </search>
     
        <el-button style="margin-left:10px" size="small" type="primary" plain @click="init(1)">刷新</el-button>

        <el-button size="small" type="success" plain @click="oneKeyLl()">一键领料</el-button>
        <el-button size="small" type="success" plain @click="ablck()">按比例出库</el-button>
      </el-col>
      <el-col :span="12">
        <div style="float:right">
          <el-button size="small" v-if="info.statusDetail && info.statusDetail.projectCount == 0" type="success" @click="doProduceClick()">工程核算</el-button>
          <el-button size="small" type="success" @click="doProduce(1)">暂存</el-button>
          <el-button size="small" v-if="info.statusDetail && info.statusDetail.projectCount == 1" type="warning" @click="reset()">重置工程核算</el-button>
          <costSetting type="project"></costSetting>
        </div>
      </el-col>
    </el-row>

    <!-- 左边 -->
    <div class="left-content">
      <div class="left-content-item">
        <div class="left-total">
          出库总金额：{{total.ckzje}} 成本比例{{total.cbbl}}%
          <div style="display:inline-block;float:right">
            <el-input style="width:120px" size="small" v-model="this.leftListQuery.inventoryName" placeholder="筛选名称"></el-input>
            <el-button @click="changeLeftInventory('find')" size="small" type="primary" plain>筛选</el-button>
          </div>
        </div>
      </div>
      <!-- 其他费用 -->
      <div v-if="info.leftFee" class="left-content-item">
          <div class="left-title">
              其他费用
          </div>
          <el-row v-for="(item,index) in oldInfo.leftFee" :key="index">
            <el-col :span="16">
              <div class="left-box">
                {{item.name}}
              </div>
            </el-col>
            <el-col :span="8">
              <div class="left-box">
                <input @change="leftTotal()" v-model.number="item.amount" placeholder="请输入金额" />
              </div>
            </el-col>
          </el-row>
      </div>


      <!-- 存货原材料 -->
      <div class="left-content-item">
        <el-row>
          <!-- title -->
          <div class="left-title">
              原材料
          </div>
          <el-col :span="3">
            <div class="left-box">
              存货数量
            </div>
          </el-col>
          <el-col :span="3">
            <div class="left-box">
              存货单价
            </div>
          </el-col>
          <el-col :span="4">
            <div class="left-box">
              存货金额
            </div>
          </el-col>
          
          <el-col :span="3">
            <div class="left-box">
              出库数量
            </div>
          </el-col>
          <el-col :span="3">
            <div class="left-box">
              出库单价
            </div>
          </el-col>
          <el-col :span="4">
            <div class="left-box">
              出库金额
            </div>
          </el-col>
          <el-col :span="4">
            <div class="left-box">
              结算后数量
            </div>
          </el-col>
        </el-row>

        <div :style="{height:contentStyleObj}" class="heightDetail">

          <!-- 明细 -->
          <el-row v-for="(item,index) in info.leftInventory" :key="index">
            <!-- title -->
            <el-col :span="24">
              <div class="leftOne subject">
                <ul style="display:inline-flex">
                  <el-tooltip effect="dark" :content="item.name" placement="top" :disabled="!item.name">
                    <li style="width:200px" class="ccyc">
                      <span >{{item.name}}</span>
                    </li>
                  </el-tooltip>
                  <el-tooltip effect="dark" :content="item.subjectName" placement="top" :disabled="!item.subjectName">
                    <li style="width:180px" class="ccyc">
                      <span >科目:{{item.subjectName}}</span>
                    </li>
                  </el-tooltip>
                  <li>类型: {{$inventoryTypeFilter(item.inventoryType)}}</li>
                  <li>单位: {{item.unit}}</li>
                </ul>
                <!-- <span>原材料名：{{item.name}}</span>
                <span>科目名称: {{ item.subjectName }}</span>
                <span>类型: {{$inventoryTypeFilter(item.inventoryType)}}</span>
                <span>单位: {{item.unit}}</span> -->
              </div>
            </el-col>
            <el-col :span="24">
              <el-row >
                <el-col :span="3">
                  <div class="left-box">
                    {{item.count}}
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="left-box">
                    <span v-if="item.count">{{(item.amount/item.count).toFixed(2)}}</span>
                    <span v-else>0</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="left-box">
                    {{item.amount}}
                  </div>
                </el-col>
                
                <el-col :span="3">
                  <div class="left-box">
                    <qzf-input @change="changeLeft(item, 'count', item)" v-model:num="item.useCount" placeholder="请输入出库数量" />
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="left-box">
                    <qzf-input @change="changeLeft(item, 'price', item)" v-model:num="item.usePrice" placeholder="请输入出库单价" />
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="left-box">
                    <qzf-input @change="changeLeft(item, 'amount', item)" v-model:num="item.useAmount" placeholder="请输入出库金额" />
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="left-box">
                    {{(item.count-item.useCount).toFixed(2)}}
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="pagination">
        <qzf-pagination v-show="leftPageTotal>0" :total="leftPageTotal" v-model:page="this.leftListQuery.page" v-model:limit="this.leftListQuery.limit" @pagination="changeLeftInventory" />
      </div>
    </div>

     <!-- 右边 -->
     <div class="right-content" style="float:right">
      <!-- 存货原材料 -->
      <div class="left-content-item">
        <el-row>
          <!-- title -->
          <div class="left-total">
            生产总金额：{{total.sczje}}
            <!-- <div style="display:inline-block;float:right">
              <el-input style="width:120px;" size="small" v-model="this.rightListQuery.inventoryName" placeholder="筛选名称"></el-input>
              <el-button @click="changeRightInventory('find')" size="small" type="primary" plain>筛选</el-button>
            </div> -->
            
          </div>
          <div class="left-title">
              工程
          </div>
          <el-col :span="16">
            <div class="left-box">
              服务
            </div>
          </el-col>
          <el-col :span="8">
            <div class="left-box">
              金额
            </div>
          </el-col>
        </el-row>
 
        <div :style="{height:contentStyleObj}" class="heightDetail" >
          <!-- 明细 -->
          <el-row v-for="(item,index) in info.rightFw" :key="index" v-if="info.rightFw">
            <el-col :span="24">
              <el-row >
                <el-col :span="16">
                  <div class="left-box">
                    {{item.name}}
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="left-box">
                    {{item.amount}}
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="pagination">
        <qzf-pagination v-show="rightPageTotal>0" :total="rightPageTotal" v-model:page="this.rightListQuery.page" v-model:limit="this.rightListQuery.limit" @pagination="changeRightInventory" />
      </div>
    </div>

    <!-- 弹框领料出库确认 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close append-to-body title="领料出库确认" v-model="dialogVisible" width="25%" >
      <el-form :model="addData" label-width="200px" label-position="right" size="small">
        <el-form-item label="成本总金额：">
        <span>{{this.total.ckzje}}</span>
        </el-form-item>
        <el-form-item label="是否需要生产成本留底：">
        <el-radio-group v-model="radio">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="radio == 1 && this.oldInfo.retentionAmount">
          <el-form-item v-for="(item,index) in this.oldInfo.retentionAmount" :key="index" :label="item.name">
            <qzf-input  v-model:num="item.amount" placeholder="请输入留底金额" />
          </el-form-item>
        </div>
      
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false" size="small">取 消</el-button>
          <el-button type="primary" @click="doProduce(0)" size="small">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 暂估入库 -->
    <inventoryStock @success="zgrkSuccess" ref="zgrk" name="暂估入库" from="convert" @convert="dialogVisible = true"></inventoryStock>
  </div>
</template>

<script>
import { projectCheckInfo, projectCheck, reverseProject } from"@/api/inventory.js"
import inventoryStock from "./components/inventoryStock.vue"
import costSetting from "./components/costSetting.vue"
export default {
  name:'projectConvert',
  components: {
    inventoryStock,
    costSetting
  },
  data() {
    return {
      radio: 0,//领料加工是否显示留底金额
      dialogVisible: false,
      loading: false,
      formLabelWidth:'120px',
      drawer: false,
      listQuery: {
        refresh: 0,//1 强制刷新
        hideEndAmount0: true,//true 隐藏金额为0的
      },
      info: {},
      oldInfo:{},
      total: {
        ckzje: 0,//出库总金额
        cbbl: 0,//成本比例
        sczje:0,//生产总金额
      },
      leftListQuery: {
        limit: 20,
        page:1,
        inventoryName: "",
      },
      leftPageTotal:10,
      rightListQuery: {
        limit: 20,
        page:1,
        inventoryName: "",
      },
      rightPageTotal:10,
    }
  },
  created() {
    this.init()
    this.contentStyleObj= this.$getHeight(340)

  },
  methods:{
     //分页筛选
    changeLeftInventory(a){
      let left = this.oldInfo.leftInventory
      let coms = []
      if(a == 'find'){
        this.leftListQuery.page = 1
        this.leftListQuery.limit = 20
        coms = left.filter(v=>{
          return v.name.indexOf(this.leftListQuery.inventoryName) > -1
        })
      } else {
        if(this.leftListQuery.inventoryName){
          coms = left.filter(v=>{
            return v.name.indexOf(this.leftListQuery.inventoryName) > -1
          })
        }else{
          coms = left
        }
      }

      this.leftPageTotal = coms.length
      this.info.leftInventory = coms.slice((this.leftListQuery.page - 1) * this.leftListQuery.limit, this.leftListQuery.page * this.leftListQuery.limit)
    },
    changeRightInventory(a){
      let right = this.oldInfo.rightFw
      let coms = []
      if(a == 'find'){
        this.rightListQuery.page = 1
        this.rightListQuery.limit = 20
        coms = right.filter(v=>{
          return v.name.indexOf(this.rightListQuery.inventoryName) > -1
        })
      } else {
        if(this.rightListQuery.inventoryName){
          coms = right.filter(v=>{
            return v.name.indexOf(this.rightListQuery.inventoryName) > -1
          })
        }else{
          coms = right
        }
      }
      if(coms){
        this.rightPageTotal = coms.length
        this.info.rightInventorys = coms.slice((this.rightListQuery.page - 1) * this.rightListQuery.limit, this.rightListQuery.page * this.rightListQuery.limit)
      }else{
        this.rightPageTotal = 0
      }
    },

    changeLeft(item, type, subject){
      if(type == 'count'){
        if(item.usePrice){
          item.useAmount = (item.useCount * item.usePrice).toFixed(2) * 1
        }else{
          if(item.count){
            item.usePrice = (item.amount/item.count).toFixed(2) * 1
            item.useAmount = (item.useCount * item.usePrice).toFixed(2) * 1
          }
        }
      }else if(type == 'price'){
        item.useAmount = (item.useCount * item.usePrice).toFixed(2) * 1
      }else{
        if(item.useCount){
          item.usePrice = (item.useAmount/item.useCount).toFixed(2) * 1
        }
      }
      this.leftTotal()
    },
    leftTotal(){
      //费用
      let feeAmount = 0
      if(this.oldInfo.leftFee){
        this.oldInfo.leftFee.map(v=>{
          feeAmount+=v.amount
        })
      }
      feeAmount = feeAmount.toFixed(2)*1
      //存货
      let inventoryAmount = 0
      if(this.oldInfo.leftInventory){
        this.oldInfo.leftInventory.map(v=>{
          if(v.useAmount){
            inventoryAmount+=v.useAmount
          }
        })
      }
      inventoryAmount = inventoryAmount.toFixed(2)*1


      //生产总金额
      let inventoryAmountSc = 0
      if(this.oldInfo.rightFw){
        this.oldInfo.rightFw.map(v=>{
          if(v.amount){
            inventoryAmountSc+=v.amount
          }
        })
      }
      this.total.sczje = inventoryAmountSc.toFixed(2) * 1
      this.total.ckzje = (feeAmount + inventoryAmount).toFixed(2) * 1
      this.total.cbbl = this.total.sczje?((this.total.ckzje/this.total.sczje) * 100).toFixed(2):0
    },
    //初始化数据
    init(refresh){
      if(refresh){
        this.listQuery.refresh = 1
      }else{
        this.listQuery.refresh = 0
      }
      this.loading = true
      projectCheckInfo(this.listQuery).then(res=>{
        this.loading = false
        this.info = res.data.data.info
        if(this.info.leftInventory){
          this.info.leftInventory.map(v=>{
            if(v.count){
              v.usePrice = (v.amount/v.count).toFixed(2) * 1
            } else {
              v.usePrice = 0
            }
          })
        }
        this.oldInfo = this.$qzfCopy(this.info)
        this.changeLeftInventory()
        this.changeRightInventory()
        this.initPrice()
      })
    },
    initPrice(){
      //计算缓存的出库单价
      if(this.oldInfo.leftInventory){
        this.oldInfo.leftInventory.map(v=>{
          this.changeLeft(v, 'amount', this.oldInfo.leftInventory)
        })
      }
      this.leftTotal()
    },
    //执行领料加工
    doProduce(type){
      let param = {
        info: this.oldInfo,
        type,
      }
    
      this.dialogVisible = false
      this.loading = true
      projectCheck(param).then(res=>{
        this.loading = false
        if(res.data.msg == "success"){
          this.$qzfMessage("操作成功")
          this.$bus.emit('inventoryCheckUpdate')
          this.init()
        }
      })
    },
    //领料加工 确认
    doProduceClick(){
      //判断是否需要暂估入库
      let zgrkList = []
      if(this.oldInfo.leftInventory){
        this.oldInfo.leftInventory.map(v=>{
          if((v.useCount - v.count) > 0){
            let count = parseFloat((v.useCount - v.count).toFixed(6) * 1)
            let amount = (v.useAmount - v.amount).toFixed(2) * 1
            zgrkList.push({
              count: count, // 数量
              amount:  amount,// 金额
              danjia: (amount/count).toFixed(2) * 1,
              inventoryId:v.id,
            })
          }
        })
      }
      if(zgrkList.length != 0){
        this.$refs.zgrk.init(zgrkList)
        return
      }
      this.dialogVisible = true
    },
    //暂估入库回调
    zgrkSuccess(info){
      info.items.map(zgrk=>{
        this.oldInfo.leftInventory.map(v=>{
          if(v.id == zgrk.inventoryId){
            v.count = parseFloat((v.count + zgrk.count).toFixed(6) * 1)
            v.amount = (v.amount + zgrk.amount).toFixed(2) * 1
          }
        })
      })
    },
    //一键领料
    oneKeyLl(){
      this.oldInfo.leftInventory.map(v=>{
        v.useCount = v.count
        v.useAmount = v.amount
      })
      this.initPrice()
    },
    ablck(){
      let that = this
      this.$prompt('请输入成本比例（%）', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        // 成功的操作 写在这里
        let amount = this.total.sczje * value / 100
        that.inProportion(amount)
      })
    },
    //按比例出库
    inProportion(amount){
      if(amount == 0){
        this.$qzfMessage("生产总金额不可为0", 1)
        return
      }

      let amountSum = 0
      this.oldInfo.leftInventory.map(v=>{
        amountSum+=v.amount
      })
      if(amount > amountSum){
        this.$qzfMessage("成本大于当前存货总金额", 1)
        return
      }
      this.oldInfo.leftInventory.map(v=>{
        v.useAmount = amountSum?(v.amount/amountSum*amount).toFixed(2) * 1:0
        v.usePrice = v.count?(v.amount/v.count).toFixed(2) * 1:0
        v.useCount = v.usePrice?parseFloat((v.useAmount/v.usePrice).toFixed(6) * 1):0
      })
      this.initPrice()
    },
    //重置领料加工
    reset(){
      this.loading = true
      reverseProject({}).then(res=>{
        this.loading = false
        if(res.data.msg == "success"){
          this.info = res.data.data.info
          if(this.info.leftInventory){
            this.info.leftInventory.map(v=>{
              if(v.count){
                v.usePrice = (v.amount/v.count).toFixed(2) * 1
              } else {
                v.usePrice = 0
              }
            })
          }
          this.oldInfo = this.$qzfCopy(this.info)
          this.changeLeftInventory()
          this.changeRightInventory()
          this.initPrice()
          this.$qzfMessage("重置成功")
          this.$bus.emit('inventoryCheckUpdate')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
 
.content{
  font-size: 14px;
  margin: 20px;
  text-indent: 4px;
  .left-content{
    width: 49%;
    margin-right: 1%;
    height: 500px;
    display: inline-block;
  }
  .right-content{
    width: 50%;
    height: 500px;
    display: inline-block;
  }
  .left-content-item{
    width: 100%;
    border: 1px solid #efefef;
    .left-total{
      width: 100%;
      background: #fff;
      height: 30px;
      text-align: left;
      line-height: 30px;
    }
    .left-title{
      width: 100%;
      background: $color-main;
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: #fff;
    }
    .subject{
      background: #efefef;
    }
    .left-box{
      border-right: 1px solid #efefef;
      line-height: 30px;
      height: 30px;
      border-top: 1px solid #efefef;
      text-align: center;
      input {
        background-color: aliceblue;
      }
    }
    &:last-child {
      border-right: none;
    }
  }
}
.right-button{
  position: fixed;
  top: 250px;
  right: 0;
  height: 120px;
  width: 40px;
  font-size: 16px;
  margin: 0 auto;  
  line-height: 24px;
  background: $color-main;
  color: #fff;
  border-color: $color-main;
  border: 0;
  cursor:pointer;
}
input{
  width: calc(100% - 10px);
  height: 100%;
  display: inline-block;
  outline: none;
}
.leftOne {
  border-right: 1px solid #efefef;
  line-height: 30px;
  height: 30px;
  border-top: 1px solid #efefef;
  padding: 0 10px;
}
.leftOne span {
  padding-right: 25px;
}
.ccyc{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
</style>